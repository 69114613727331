import React from 'react';
import Button, { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';

const scope = 'shared.visualizations.panes.data';
interface SelectAllButtonProps {
  disabled: boolean;
  onClick: () => void;
}

const SelectAllButton = ({ disabled, onClick }: SelectAllButtonProps) => {
  return (
    <div className="select-all-button" aria-label={I18n.t('fields.columns.select_all', { scope })}>
      <Button className="select-all" disabled={disabled} variant={VARIANTS.LINK} onClick={onClick}>
        {I18n.t('fields.columns.select_all', { scope })}
      </Button>
    </div>
  );
};

export default SelectAllButton;
