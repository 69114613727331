import React, { useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { ForgeDivider, ForgeSkeleton } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';
import ProcessDiscoverySelect from './AgGridUpdated/AgGridComponents/ProcessDiscoverySelect';
import ActionSelectorCheckbox from './ActionSelectorCheckbox';
import MultiActionSelector from './AgGridUpdated/AgGridComponents/MultiActionSelector';

import { useProcessDiscoveryResults } from 'common/visualizations/views/agGridReact/customHooks/useProcessDiscoveryResults';
import {
  setShowExistingActionColumn,
  setShowProcessDiscoveryAction,
  setSelectedExistingColumn,
  setSelectedActionForExistingColumn,
  setNewTableActions
} from 'common/authoring_workflow/actions.js';
import {
  getTableColumns,
  getShowProcessDiscoveryAction,
  getShowExistingActionColumn,
  getSelectedExistingColumn,
  getNewTableActions,
  getSelectedActionForExistingColumn
} from 'common/authoring_workflow/selectors/vifAuthoring.js';
import { getCurrentMetadata } from 'common/authoring_workflow/selectors/metadata';
import { getProcessDiscoveryContext } from 'common/visualizations/views/agGridReact/helpers/processDiscoveryHelper';
import './ProcessDiscovery.scss';

const scope = 'shared.visualizations.panes.presentation.fields.actions';

const ProcessDiscoveryActions = ({
  vifAuthoring,
  metadata,
  onSetExistingActionColumn,
  onSetShowProcessDiscoveryAction,
  onSetSelectedExistingColumn,
  onSetSelectedActionForExistingColumn,
  onSetNewTableActions
}) => {
  const tableColumns = getTableColumns(vifAuthoring);
  const contextParams = getProcessDiscoveryContext(metadata);
  const showExistingActionColumn = getShowExistingActionColumn(vifAuthoring);
  const showProcessDiscoveryAction = getShowProcessDiscoveryAction(vifAuthoring);
  const selectedExistingColumn = getSelectedExistingColumn(vifAuthoring);
  const selectedActionForExistingColumn = getSelectedActionForExistingColumn(vifAuthoring);
  const newTableActions = getNewTableActions(vifAuthoring);
  const primaryValue = isEmpty(metadata) ? '' : 'processDiscoveryActions';
  const { processDiscoveryResults, loading } = useProcessDiscoveryResults(primaryValue, contextParams, {
    showAllActions: true
  });
  const { processDescriptors = [] } = get(processDiscoveryResults, '[0]', {});

  const columns = tableColumns.map((column) => {
    return {
      label: column.name,
      value: column.fieldName
    };
  });
  const actionOptions = useMemo(() => {
    return processDescriptors.map((processDescriptor) => {
      return {
        label: processDescriptor.name,
        value: processDescriptor.id
      };
    });
  }, [processDescriptors]);

  const onColumnSelect = (event) => {
    onSetSelectedExistingColumn(event.detail);
  };

  const onActionSelect = (event) => {
    onSetSelectedActionForExistingColumn(event.detail);
  };

  const onNewActionClick = () => {
    onSetShowProcessDiscoveryAction(!showProcessDiscoveryAction);
  };

  const onExistingActionClick = () => {
    onSetExistingActionColumn(!showExistingActionColumn);
  };

  const onMultipleActionSelect = (actions) => {
    onSetNewTableActions(actions);
  };

  return (
    <>
      <div className="authoring-field process-discovery" key="actionColumnSelector">
        <div className="action-column-selector-container">
          <BlockLabel title={I18n.t('enable_in', { scope })} titleClassName="forge-typography--overline" />
          <ActionSelectorCheckbox
            dense={true}
            label={I18n.t('new_action_column', { scope })}
            isChecked={showProcessDiscoveryAction}
            onClick={onNewActionClick}
          />
          {showProcessDiscoveryAction &&
            (loading ? (
              <ForgeSkeleton form-field></ForgeSkeleton>
            ) : (
              <MultiActionSelector
                options={actionOptions}
                label={I18n.t('title', { scope })}
                value={newTableActions}
                onChange={onMultipleActionSelect}
              />
            ))}
          <ForgeDivider />
          <ActionSelectorCheckbox
            dense={false}
            label={I18n.t('existing.title', { scope })}
            isChecked={showExistingActionColumn}
            onClick={onExistingActionClick}
          />
          {showExistingActionColumn && (
            <>
              <ProcessDiscoverySelect
                options={columns}
                placeHolder={I18n.t('existing.column_placeholder', { scope })}
                value={selectedExistingColumn}
                onChange={onColumnSelect}
                label={I18n.t('existing.column_label', { scope })}
              />
              <ProcessDiscoverySelect
                options={actionOptions}
                placeHolder={I18n.t('existing.action_placeholder', { scope })}
                label={I18n.t('existing.action_label', { scope })}
                value={selectedActionForExistingColumn}
                onChange={onActionSelect}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { data } = getCurrentMetadata(state.metadataCollection, state.vifAuthoring);
  return {
    vifAuthoring: state.vifAuthoring,
    metadata: data
  };
};

const mapDispatchToProps = {
  onSetExistingActionColumn: setShowExistingActionColumn,
  onSetShowProcessDiscoveryAction: setShowProcessDiscoveryAction,
  onSetSelectedExistingColumn: setSelectedExistingColumn,
  onSetSelectedActionForExistingColumn: setSelectedActionForExistingColumn,
  onSetNewTableActions: setNewTableActions
};

export { ProcessDiscoveryActions };
export default connect(mapStateToProps, mapDispatchToProps)(ProcessDiscoveryActions);
