import { isEmpty, map } from 'lodash';
import React, { useEffect } from 'react';
import { ForgeSelect } from '@tylertech/forge-react';

import SelectAllButton from '../../../DataPane/TableColumnsSelector/SelectAllButton';

interface Option {
  label: string;
  value: string;
}

interface MultiActionSelectorProps {
  options: Option[];
  value: string[];
  label: string;
  loading: boolean;
  onChange: (newActions: string[]) => void;
}

const MultiActionSelector = ({
  options,
  label,
  onChange,
  value = []
}: MultiActionSelectorProps): JSX.Element => {
  const forgeSelectAttributes = {
    'data-testid': 'multi-action-selector',
    label: label,
    onChange: (event: CustomEvent) => onChange(event.detail),
    floatLabelType: 'always',
    options,
    value,
    multiple: true
  };

  const handleSelectAllColumns = () => {
    onChange(map(options, 'value'));
  };

  useEffect(() => {
    if (isEmpty(value) && !isEmpty(options)) {
      onChange(map(options, 'value'));
    }
  }, [value, options]);
  const isDisabled = value.length === options.length;

  return (
    <div className="authoring-field" key="multi-action-selector">
      <SelectAllButton disabled={isDisabled} onClick={handleSelectAllColumns} />
      <div className="action-column-selector-container">
        <ForgeSelect {...forgeSelectAttributes} />
      </div>
    </div>
  );
};

export default MultiActionSelector;
