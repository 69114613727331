import { ForgeDialog } from '@tylertech/forge-react';
import React, { useEffect, useState } from 'react';

import './custom-sort.scss';
import { ForgeScaffold } from '@tylertech/forge-react';
import { ForgeToolbar } from '@tylertech/forge-react';
import { ForgeButton } from '@tylertech/forge-react';
import { ForgeSelect } from '@tylertech/forge-react';
import { ViewColumn } from 'common/types/viewColumn';
import { map } from 'lodash';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import I18n from 'common/i18n';
import UniqueValuesContainer from './UniqueValuesContainer';
import { getAgTableHeaderName } from 'common/visualizations/views/agGridReact/helpers/TableColumnFormatting';
import { UniqueValuesData } from 'common/visualizations/views/agGridReact/types';
import { OrderConfig } from 'common/visualizations/vif';

const scope = 'shared.components.custom_sort.modal';

interface CustomSortModalProps {
  isCustomSortModalOpen: boolean;
  setIsCustomSortModalOpen: (isCustomSortModalOpen: any) => void;
  columnsToCustomSort: ViewColumn[];
  columnFormats: { [key: string]: TableColumnFormat };
  getUniqueValues?: (columns: ViewColumn[], order: OrderConfig[]) => Promise<UniqueValuesData>;
  vifOrderConfig: OrderConfig[];
}

const CustomSortModal: React.FC<CustomSortModalProps> = ({
  isCustomSortModalOpen,
  setIsCustomSortModalOpen,
  columnsToCustomSort,
  columnFormats,
  getUniqueValues,
  vifOrderConfig
}) => {
  const [uniqueValues, setUniqueValues] = useState<unknown[]>([]);
  const [isModalInitial, setIsModalInitial] = useState<boolean>(false);

  const hideModal = () => {
    setIsCustomSortModalOpen(false);
  };

  const handleReset = () => {
    // TODO: EN-75876 add reset logic here
  };

  const handleApply = () => {
    // TODO: EN-75852 add apply logic here
  };

  // const computeModalOffset = () => {
  //   // Offset modal's y-position so it would be slightly off-center
  //   // based on figma design. The following formula should be good
  //   // for 1080p and above resolutions.
  //   return -0.4242 * window.outerHeight + 334.8;
  // };

  const dialogAttributes = {
    'aria-labelledby': 'custom-sort-dialog',
    'aria-describedby': 'custom-sort-dialog',
    backdropClose: false
    // customPosition: true,
    // positionX: 0,
    // positionY: computeModalOffset(),
    // positionType: 'relative'
  };

  const mappedColumnsToCustomSort = map(columnsToCustomSort, (col) => {
    const columnFormat = columnFormats?.[col.fieldName];
    const displayName = getAgTableHeaderName(columnFormat, col);
    return {
      label: displayName,
      value: col.fieldName
    };
  });

  const initialColumnToSort =
    mappedColumnsToCustomSort.length > 0 ? mappedColumnsToCustomSort[0].value : null;

  useEffect(() => {
    if (!isModalInitial && isCustomSortModalOpen) {
      setIsModalInitial(true);
    }
    if (isModalInitial && getUniqueValues && initialColumnToSort) {
      getUniqueValues([columnsToCustomSort[0]], vifOrderConfig).then((data) => {
        const rows = data.rows;
        setUniqueValues(rows);
      });
    }
  }, [isModalInitial]);

  const [columnToSort, setColumnToSort] = useState<string | null>(initialColumnToSort);

  const selectAttributes = {
    'data-testid': 'custom-sort-dialog-dropdown',
    options: mappedColumnsToCustomSort,
    value: columnToSort,
    label: I18n.t('dropdown_label', { scope }),
    floatLabelType: 'always',
    onChange: (event: CustomEvent) => {
      if (columnToSort === event.detail) return;
      const selectedColumn = columnsToCustomSort.find(
        (col) => col.fieldName.toLocaleLowerCase() === event.detail.toLocaleLowerCase()
      );
      if (!selectedColumn || !getUniqueValues) return;
      setColumnToSort(event.detail);
      getUniqueValues([selectedColumn], vifOrderConfig).then((data) => {
        setUniqueValues(data.rows);
      });
    }
  };

  return (
    <ForgeDialog onDismiss={hideModal} open={isCustomSortModalOpen} options={dialogAttributes}>
      <div data-testid="custom-sort-dialog">
        <ForgeScaffold className="custom-sort-scaffold">
          <ForgeToolbar slot="header" no-divider>
            <h1 id="dialog-title" slot="start" className="forge-typography--title custom-sort-title">
              {I18n.t('title', { scope })}
            </h1>
          </ForgeToolbar>
          <div slot="body" className="custom-sort-modal-body">
            <ForgeSelect {...selectAttributes} />
            <br />
            {uniqueValues?.length > 0 && isCustomSortModalOpen && (
              <UniqueValuesContainer
                columnsToCustomSort={columnsToCustomSort}
                columnFormats={columnFormats}
                data={uniqueValues}
                isCustomSortModalOpen={isCustomSortModalOpen}
                columnToSort={columnToSort}
              />
            )}
          </div>
          <ForgeToolbar slot="footer" no-divider>
            <ForgeButton slot="start" className="custom-sort-modal-reset-button" onClick={handleReset}>
              <button type="button">{I18n.t('reset', { scope })}</button>
            </ForgeButton>
            <div className="custom-sort-modal-footer-end" slot="end">
              <ForgeButton type="outlined" onClick={hideModal}>
                <button type="button">{I18n.t('cancel', { scope })}</button>
              </ForgeButton>
              <ForgeButton type="unelevated" onClick={handleApply}>
                <button type="button">{I18n.t('apply', { scope })}</button>
              </ForgeButton>
            </div>
          </ForgeToolbar>
        </ForgeScaffold>
      </div>
    </ForgeDialog>
  );
};

export default CustomSortModal;
