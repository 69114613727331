import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import DragDropContainer, { DragDropContainerType } from 'common/components/DragDropContainer';
import { ViewColumn } from 'common/types/viewColumn';
import React, { useEffect, useState } from 'react';
import I18n from 'common/i18n';

import './unique-values.scss';
import { isEmpty } from 'lodash';
import { modalValuesDataFormatter } from 'common/visualizations/views/agGridReact/helpers/TableColumnFormatting';

interface UniqueValuesContainerProps {
  columnsToCustomSort: ViewColumn[];
  columnFormats: { [key: string]: TableColumnFormat };
  data: unknown[];
  isCustomSortModalOpen: boolean;
  columnToSort: string | null;
}

const UniqueValuesContainer: React.FC<UniqueValuesContainerProps> = ({
  columnsToCustomSort,
  columnFormats,
  data,
  isCustomSortModalOpen,
  columnToSort
}) => {
  const [uniqueValues, setUniqueValues] = useState<any[]>([]);

  const isUrl = (value: any) => {
    return typeof value === 'object';
  };

  const isNoValue = (datum: unknown) => {
    return isEmpty(datum);
  };

  const processData = (unprocessedData: unknown[]) => {
    return unprocessedData
      .map((datum) => {
        if (isNoValue(datum)) {
          return I18n.t('shared.visualizations.charts.common.summary_table.no_value');
        }
        if (typeof datum === 'object' && datum !== null) {
          const value = Object.values(datum as Record<string, any>)[0];
          let processedDatum: any = value;
          if (isUrl(value)) {
            const objectValues = Object.values(value as Record<string, any>);
            processedDatum = objectValues[objectValues.length - 1];
          }
          processedDatum = modalValuesDataFormatter({
            viewColumns: columnsToCustomSort,
            value: processedDatum,
            selectedColumn: columnToSort,
            columnFormats: columnFormats
          });
          return processedDatum;
        }
        return undefined;
      })
      .filter((datum) => datum !== undefined);
  };

  useEffect(() => {
    const processedData = processData(data);
    setUniqueValues(processedData);
  }, [data]);

  const valuesHtml = uniqueValues.map((value) => (
    <span key={value} dangerouslySetInnerHTML={{ __html: value }} />
  ));
  const wrappedValuesHtml = valuesHtml.map((item, i) => ({
    dragAndDroppable: true,
    element: item,
    additionalItemProps: {
      dataTestId: `custom-sort-order-unique-value-${i}`
    }
  }));

  return (
    <div>
      {data.length > 0 && isCustomSortModalOpen && (
        <DragDropContainer
          type={DragDropContainerType.FORGE_LIST}
          items={uniqueValues}
          onDrop={(newValues) => {
            setUniqueValues(newValues);
          }}
          childElements={wrappedValuesHtml}
          useForgeIcon={true}
          className={'unique-values'}
        />
      )}
    </div>
  );
};

export default UniqueValuesContainer;
