import React from 'react';
import { ForgeCheckbox } from '@tylertech/forge-react';

interface ActionSelectorCheckboxProps {
  isChecked: boolean;
  label: string;
  onClick: () => void;
  dense: boolean;
}

const ActionSelectorCheckbox = ({ isChecked, label, onClick, dense }: ActionSelectorCheckboxProps) => {
  return (
    <ForgeCheckbox dense={dense} data-testid="action-selector-checkbox">
      <input id={label} type="checkbox" aria-label={label} checked={isChecked} onClick={onClick} readOnly />
      <label htmlFor={label} slot="label">
        {label}
      </label>
    </ForgeCheckbox>
  );
};

export default ActionSelectorCheckbox;
